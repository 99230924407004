import * as React from "react"

import { graphql } from "gatsby"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import MainTitle from "decentraland-gatsby/dist/components/Text/MainTitle"
import { Link } from "decentraland-gatsby/dist/plugins/intl"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid/Grid"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon"

import Layout from "../components/Layout/Layout"
import PlayMenu from "../components/Layout/PlayMenu"

import "./markdown/index.css"

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      headings {
        id
        value
        depth
      }
      frontmatter {
        title
        description
        image
        slug
        menu
        icon
      }
    }
    allMarkdownRemark(filter: { frontmatter: { menu: { ne: null } } }) {
      nodes {
        id
        frontmatter {
          title
          description
          image
          slug
          menu
          icon
        }
      }
    }
  }
`

export type MarkdownData = {
  id: string
  frontmatter: {
    title: string
    description: string
    image: string
    slug: string
    menu: string
    icon: string
  }
}

export type MarkdownPages = {
  uri: string
  path: string
  location: Location
  params: {
    frontmatter__slug: string
  }
  data: {
    allMarkdownRemark: { nodes: MarkdownData[] }
    markdownRemark: MarkdownData & {
      html: string
      headings: {
        id: string
        value: string
        depth: number
      }[]
    }
  }
}

export default function MarkdownPages(props: any) {
  const data = props.data as MarkdownPages["data"]
  const remark = data && data?.markdownRemark
  const pages = (data && data?.allMarkdownRemark?.nodes) || []

  if (!remark) {
    return (
      <Layout
        {...props}
        data={{}}
        isFullscreen
        isOverlay
        rightMenu={<PlayMenu />}
      >
        <NotFound />
      </Layout>
    )
  }
  const { frontmatter, html, headings } = remark

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      rightMenu={<PlayMenu />}
      className="layout__markdown"
    >
      <Head title={frontmatter.title} description={frontmatter.description} />
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column tablet="4">
              <ul className="sidebar">
                {pages.map((page: MarkdownData) => {
                  return (
                    <li key={page.id}>
                      <Button
                        fluid
                        labelPosition="left"
                        as={Link}
                        active={page.id === props.pageContext.id}
                        href={page.frontmatter.slug}
                      >
                        <Icon name={page.frontmatter.icon as any} />
                        {page.frontmatter.menu}
                      </Button>
                    </li>
                  )
                })}
              </ul>
            </Grid.Column>
            <Grid.Column tablet="12">
              <MainTitle>{frontmatter.title}</MainTitle>
              {headings.length > 1 && (
                <div>
                  <ul className="index__list">
                    {headings.map((heading) => {
                      return (
                        <li
                          key={heading.id}
                          style={{ paddingLeft: heading.depth - 2 + "rem" }}
                        >
                          <a href={`#${heading.id}`}>{heading.value}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <div
                className="post-body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Layout>
  )
}
